// axios
import axios from 'axios'
import https from 'https';

const baseURL = 'https://titos.com.es/backend'

export default axios.create({
  baseURL,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
  // You can add your headers here
})

