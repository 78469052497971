import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import router from './router'
import VueMeta from 'vue-meta'
import VueSilentbox from 'vue-silentbox'
import BackToTop from 'vue-backtotop'

// axios
import axios from './axios.js'
import vueMeta from 'vue-meta'
Vue.prototype.$http = axios

import VueAxios from "vue-axios";

import Vuex from 'vuex'

// Vuex Store
import store from './store/store'

// Vue.prototype.$baseURL1 = 'https://titos.com.es/backend/images/upload/'

Vue.use(Vuex)
Vue.use(vueMeta)

Vue.use(VueAxios, axios)

Vue.use(VueSilentbox)
Vue.use(BootstrapVue)
Vue.use(BackToTop)
Vue.use(VueAwesomeSwiper)
Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
})

// Vue.component('navbar-component', require('./views/admin/module/NavbarComponent.vue').default);
// Vue.component('sidebar-component', require('./views/admin/module/SidebarComponent.vue').default);


import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../src/assets/css/font-awesome.min.css'
import '../src/assets/css/material-design-iconic-font.min.css'
import '../src/assets/css/ionicons.min.css'
import '../src/assets/css/flaticon.min.css'
import 'swiper/css/swiper.min.css'
import '../src/assets/css/animate.min.css'
import '../src/assets/scss/style.scss'

// import '../src/assets/admin/scss/app.scss'
Vue.config.productionTip = false

new Vue({
    router,
    store,
    // data() {
    //     return {
    //         baseURL: 'https://titos.com.es/backend/images/upload/'
    //     }
    // },
    render: h => h(App)
}).$mount('#app')
